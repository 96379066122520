.lang-span {
    border-radius: 500px;
    background: #f9e9f5;
    border: 1px solid rgb(166 48 116);
    color: rgb(70, 25, 51);
    padding: 2%;
    cursor: pointer;
}

.header-lang {
    margin-left: 1%;

    label {
        writing-mode: vertical-rl;
        color: rgb(40, 37, 37);
        font-size: 1px;
        margin: 0;
        padding: 0;
    }

    option {
        color: rgb(70, 25, 51);
    }
}

.label-hidden {
    display: none;
}
