.language-wrapper {
    padding: 2%;
    -webkit-box-shadow: 3px 3px 6px #777;
    box-shadow: 3px 3px 6px #777;
    border-radius: 3px;
    list-style-position: inside;

    a,
    li {
        line-height: 1.6;
    }

    ul li {
        list-style: inside;
    }
}

@media (max-width: 1025px) {
    .language-wrapper {
        width: 50%;
        margin: auto;
        text-align: center;

        h2 {
            margin-bottom: 4%;
        }
    }
}

@media (max-width: 700px) {
    .language-wrapper {
        width: 72%;
        margin-bottom: 6%;
    }
}

@media (max-width: 550px) {
    .language-wrapper {
        width: 100%;

        h2 {
            text-decoration: none;
        }

        li {
            letter-spacing: 1px;
        }
    }
}
