$maxWidth: 1280;
@mixin adaptive-font($pcSize, $mobSize) {
    $addSize: $pcSize - $mobSize;
    $addMobSize: $addSize + $addSize * 0.7;
    @media (max-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
    }
    @media (min-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
    }
}

.start-wrapper {
    width: 100%;
    background-color: rgb(70, 25, 51);
    background-image: linear-gradient(to bottom, #461933, #371629, #291220, #1c0d16, #0d0409);
    height: 100vh;
    display: flex;
}

.start-content-wrapper {
    width: 58%;
    margin: auto;
    background-image: url('../../../public/bg-start.jpeg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    height: 57%;
    border: 17px outset white;
    border-radius: 50px;

    h1 {
        @include adaptive-font(58, 38);
        text-shadow: 4px 5px 9px #00000080;
        color: #461933;
    }

    h2 {
        @include adaptive-font(28, 23);
        margin-bottom: 2%;
        text-shadow: 4px 5px 9px #00000080;
        color: #461933;
    }
}

.start-block {
    width: 55%;
    margin-top: 12%;
    padding: 2%;
}

.start-logo {
    text-align: center;
}

.start-content {
    margin-top: 6%;

    text-align: center;
}

.start-job-status {
    @include adaptive-font(18, 12);
    cursor: pointer;
    overflow: hidden;
    position: relative;
    background-color: rgb(70, 25, 51);
    color: white;
    width: 52%;
    font-size: 19px;
    padding: 3%;
    border-radius: 5px;
    -webkit-box-shadow: 3px 3px 6px #777;

    @keyframes slideme {
        0% {
            left: -30px;
            margin-left: 0px;
        }
        30% {
            left: 110%;
            margin-left: 80px;
        }
        100% {
            left: 110%;
            margin-left: 80px;
        }
    }
}

.start-job-status:after {
    content: '';
    display: block;
    width: 30px;
    height: 200px;
    margin-left: 60px;
    background: #fff;
    opacity: 0.5;
    position: absolute;
    left: -40px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    transition: all 0.1s;
    animation-name: slideme;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.button-block {
    text-align: end;
    margin-bottom: 5%;

    a {
        cursor: pointer;
        color: white;
        text-decoration: none;
    }
}

.start-btn-cv {
    margin-right: 5%;
}

@media (max-width: 1630px) {
    .start-content-wrapper {
        width: 59%;
    }

    .start-block {
        width: 60%;
    }

    .button-block {
        margin-bottom: 5%;
    }
}

@media (max-width: 1400px) {
    .start-content-wrapper {
        width: 63%;
    }

    .start-job-status {
        width: 58%;
    }
}

@media (max-width: 1300px) {
    .start-content {
        margin-top: 9%;
    }
}

@media (max-width: 1200px) {
    .start-block {
        margin-top: 15%;
    }
}

@media (max-width: 1100px) {
    .start-content-wrapper {
        width: 67%;
    }

    .start-block {
        width: 59%;
        margin-top: 15%;
    }
}

@media (max-width: 1020px) {
    .start-block {
        width: 61%;
        margin-top: 18%;
    }
}

@media (max-width: 960px) {
    .start-block {
        width: 64%;
        margin-top: 21%;
    }
}

@media (max-width: 900px) {
    .start-content-wrapper {
        width: 69%;
    }

    .start-block {
        width: 65%;
        
    }
}

@media (max-width: 850px) {
    .start-block {
        width: 71%;
        
    }

    .start-logo {
        text-align: start;
        padding: 2%;
    }

    .start-content {
        text-align: start;
        padding: 2%;
    }
}

@media (max-width: 800px) {
    .start-content-wrapper {
        box-shadow: inset 0 0 0 1000px #ffffff80;
    }

    .start-block {
        width: 75%;
        margin: auto;
        margin-top: 28%;
        padding: 0;
    }

    .start-logo {
        text-align: center;
    }

    .start-content {
        text-align: center;
    }
}

@media (max-width: 700px) {
    .start-content-wrapper {
        background-size: cover;
    }

    .start-block {
        width: 77%;
        margin-top: 28%;
    }
}

@media (max-width: 650px) {
    .start-block {
        width: 80%;
        margin-top: 32%;
    }
}

@media (max-width: 630px) {
   

    .start-block {
        width: 82%;
        margin-top: 34%;
    }
}

@media (max-width: 600px) {
    .start-content-wrapper {
        box-shadow: inset 0 0 0 1000px #ffffff80;
    }

    .start-block {
        margin-top: 38%;
        width: 88%;
    }

    .start-content {
        padding: 0;
    }
}

@media (max-width: 540px) {
    .start-logo {
        margin-top: 18%;
    }
    .start-block {
        width: 98%;
        
    }
}

@media (max-width: 470px) {
    .start-content-wrapper {
        width: 81%;
    }

    .start-block {
        width: 100%;
    }

    .start-logo {
        width: 100%;
    }

    .start-content {
        width: 100%;
    }
}

@media (max-width: 450px) {
    .start-block {       

        h1 {
            margin-bottom: 1%;
        }
    }
}

@media (max-width: 420px) {
    .start-block {
        margin-top: 40%;
    }
}

@media (max-width: 375px) {
    .start-content-wrapper {
        width: 84%;
    }

    .start-block {      
        h1 {
            margin-bottom: 3%;
        }
    }
}

    
@media (max-width: 360px) {    

.start-logo  {
    padding: 0;
}
}

@media (max-width: 345px) {
    .start-job-status {
        width: 61%;
    }

    .start-content-wrapper {
        width: 85%;
    }

    .start-block {
       
    }
}

@media (max-width: 325px) {
    .start-content-wrapper {
        width: 89%;
    }

    .start-job-status {
        width: 62%;
    }
}




@media (max-width: 285px) {
    .start-job-status {
        width: 68%;
    }

    .start-block {
        margin-top: 54%;
    }
}


@media (max-width: 250px) {
    .start-job-status {
        width: 74%;
    }

    .start-block {
        margin-top: 76%;
    }
}