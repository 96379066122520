.interests-wrapper {
    border-top: 2px solid rgb(70, 25, 51);
}

.interests-wrapper h2 {
    margin-top: 2%;
    padding-bottom: 2%;
    border-bottom: 2px solid rgb(70, 25, 51);
    text-align: center;
    /*  text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5); */
}

.conset-wrapper {
    margin-top: 5%;
    line-height: 1.7;
    border-top: 2px solid rgb(70, 25, 51);
}

.signature {
    text-align: right;
    text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5);
}

.footer-wrapper {
    margin-top: 5%;
}

@media (max-width: 1025px) {
    .interests-wrapper {
        text-align: center;
    }
}

@media (max-width: 450px) {
    .interests-wrapper {
        border-top: none;

        h2 {
            padding: 2%;
            -webkit-box-shadow: 3px 3px 6px #777;
            border-radius: 3px;
            border-bottom: none;
            text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5);
            margin-top: 7%;
            margin-bottom: 7%;
        }
    }

    .footer-wrapper {
        margin-top: 7%;
    }
}
