.header-wrapper {
    display: flex;
    margin-bottom: 5%;
    align-items: center;

    h1,
    h2 {
        text-shadow: 4px 5px 9px rgba(0, 0, 0, 0.5);
    }
}

.header-logo {
    text-align: center;
    width: 100%;
}

@media (max-width: 1280px) {
    .header-wrapper {
        margin-top: 3%;
    }
}

@media (max-width: 1025px) {
    .header-wrapper {
        display: none;
    }
}
