.general-wrapper {
    text-align: justify;
    line-height: 1.4;
    margin-bottom: 5%;
}

@media (min-width: 767px) {
    p {
        font-size: calc(16px + 4 * (100vw / 1280));
    }
}
