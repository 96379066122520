.letter {
    width: 100%;
    /*background-image: url("../../../public/bg-main.jpeg");*/
    background-size: 100% 100%;
    -webkit-box-shadow: inset 0px 0px 23px 4px rgba(40, 3, 30, 0.75);
    box-shadow: inset 0px 0px 23px 4px rgba(40, 3, 30, 0.75);

    h1 {
        color: #461933;
        text-shadow: 4px 5px 9px #00000080;
        text-align: center;
    }

    p {
        margin-top: 1%;
        margin-bottom: 1%;
    }
}

.letter-wrapper {
    display: flex;
    text-align: justify;
    text-indent: 4%;
    margin: auto;
    line-height: 1.5;

    a,
    h2 {
        color: white;
    }
}

.letter-contacts-data {
    width: 32%;
    background-color: rgb(70, 25, 51);
    padding: 5%;
}

.letter-contacts-wrapper {
    padding: 2%;
    box-shadow: 3px 3px 6px #777;
    border-radius: 3px;
    margin-top: 10%;
    text-align: center;
}

.letter-content-wrapper {
    width: 68%;
    background-color: #f8fcff;
}
.letter-block {
    margin-top: 5%;
    padding: 6%;
}

@media (max-width: 1300px) {
    .letter-contacts-data {
        padding: 3%;
    }

    .letter-contacts-wrapper {
        margin-top: 15%;
    }
}

@media (max-width: 1100px) {
    .letter-contacts-data {
        padding: 2%;
    }

    .letter-contacts-wrapper {
        margin-top: 21%;
    }
}

@media (max-width: 1025px) {
    .letter-wrapper {
        display: block;
        margin-top: 3%;
    }

    .letter-contacts-data {
        width: 100%;
        padding: 5%;
    }

    .letter-contacts-wrapper {
        width: 40%;
        margin: auto;
        text-align: center;
        margin-top: 5%;
    }

    .letter-content-wrapper {
        width: 100%;
    }

    .letter-block {
        margin: 0;
    }
}

@media (max-width: 1024px) {
    .letter-contacts-wrapper {
        margin-top: 7%;
    }

    .letter-contacts-data {
    }
}

@media (max-width: 900px) {
    .letter-contacts-wrapper {
        margin-top: 9%;
        width: 47%;
    }
}

@media (max-width: 800px) {
    .letter-contacts-wrapper {
        margin-top: 11%;
        width: 54;
    }
}

@media (max-width: 700px) {
    .letter-contacts-wrapper {
        margin-top: 8%;
        width: 56%;
    }
}

@media (max-width: 600px) {
    .letter-contacts-wrapper {
        margin-top: 8%;
        width: 62%;
    }
}

@media (max-width: 500px) {
    .letter-contacts-wrapper {
        margin-top: 12%;
        width: 77%;
    }
}

@media (max-width: 400px) {
    .letter-contacts-wrapper {
        margin-top: 15%;
        width: 82%;
    }
}

@media (max-width: 320px) {
    .letter-contacts-wrapper {
        margin-top: 18%;
        width: 97%;
    }
}
