.contacts-wrapper,
.skills-wrapper {
    margin-bottom: 15%;
    padding: 2%;
    -webkit-box-shadow: 3px 3px 6px #777;
    box-shadow: 3px 3px 6px #777;
    border-radius: 3px;

    ul li {
        list-style: inside;
    }
}

.social-btns {
    display: flex;
    justify-content: space-evenly;
}

.contact-item {
    cursor: pointer;
    display: flex;
    text-wrap: nowrap;

    img {
        width: 6%;
    }

    a {
        text-decoration: none;
        margin-left: 4%;
        line-height: 38px;
    }
}

.age-line {
    text-decoration: none;
    margin-left: 4%;
    line-height: 38px;
    color: white;
    font-size: calc(19px + 3* 100vw / 1280);
}


@media (max-width: 1025px) {
    .contacts-wrapper,
    .skills-wrapper {
        width: 50%;
        margin: auto;
        margin-bottom: 3%;

        h2 {
            margin-bottom: 4%;
        }
    }

    .contact-item {
        justify-content: center;
    }

    .social-btns {
        width: 55%;
        margin: auto;
        margin-top: 4%;
    }
}

@media (max-width: 700px) {
    .contacts-wrapper {
        width: 72%;
        margin-bottom: 6%;
    }
}

@media (max-width: 550px) {
    .contacts-wrapper {
        width: 100%;
        border-bottom: 2px solid white;

        h2 {
            text-decoration: none;
        }

        li {
            letter-spacing: 1px;
        }
    }

    .contact-item img {
        width: 4%;
    }
}

@media (max-width: 395px) {
    .contact-item img {
        width: 5%;
    }

    .ico img {
        height: 27px;
    }
}
