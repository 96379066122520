.avatar-wrapper {
    margin-bottom: 8%;
}

.photo-block {
    max-width: 45%;
    margin: auto;

    img {
        width: 100%;
        border-radius: 100%;
    }
}

@media (max-width: 1280px) {
    .avatar-wrapper {
        margin-top: 14%;
    }

    .photo-block {
        max-width: 54%;
    }
}

@media (max-width: 1200px) {
    .photo-block {
        max-width: 55%;
    }
}

@media (max-width: 1025px) {
    .avatar-wrapper {
        margin-bottom: 3%;
        margin-top: 12%;
    }

    .photo-block {
        max-width: 23%;
        margin: auto;

        img {
            width: 100%;
            border-radius: 100%;
        }
    }
}

@media (max-width: 905px) {
    .avatar-wrapper {
        margin-top: 15%;
    }
}

@media (max-width: 800px) {
    .photo-block {
        max-width: 25%;
    }

    .avatar-wrapper {
        margin-top: 18%;
    }
}

@media (max-width: 700px) {
    .avatar-wrapper {
        margin-top: 10%;
    }
}

@media (max-width: 650px) {
    .photo-block {
        max-width: 27%;
    }

    .avatar-wrapper {
        margin-top: 13%;
    }
}

@media (max-width: 530px) {
    .avatar-wrapper {
        margin-top: 15%;
    }

    .photo-block {
        max-width: 29%;
    }
}

@media (max-width: 460px) {
    .photo-block {
        max-width: 35%;
    }

    .avatar-wrapper {
        margin-top: 19%;
    }
}

@media (max-width: 360px) {
    .photo-block {
        max-width: 38%;
    }

    .avatar-wrapper {
        margin-top: 22%;
    }
}

@media (max-width: 312px) {
    .avatar-wrapper {
        margin-top: 25%;
    }

    .photo-block {
        max-width: 40%;
    }
}

@media (max-width: 290px) {
    .avatar-wrapper {
        margin-top: 28%;
    }

    .photo-block {
        max-width: 46%;
    }
}
