.skills-wrapper {
    line-height: 1.7;
    list-style-position: inside;
}

@media (max-width: 1025px) {
    .skills-wrapper {
        width: 50%;
        margin: auto;
        margin-bottom: 3%;
        text-align: center;
    }
}

@media (max-width: 700px) {
    .skills-wrapper {
        width: 72%;
        margin-bottom: 6%;
    }
}

@media (max-width: 550px) {
    .skills-wrapper {
        width: 100%;
        border-bottom: 2px solid white;
        padding-bottom: 5%;

        h2 {
            text-decoration: none;
        }

        li {
            letter-spacing: 1px;
        }
    }
}
