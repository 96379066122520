.social {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    background: #479bf1;
    color: white;
    box-sizing: content-box;
    justify-content: center;
    margin: auto;
    border-radius: 5px;
}

.ico img {
    height: 30px;
    margin: 5px;
    filter: grayscale(70%);
}

.ico {
    margin-bottom: 10px;
}

.soc-pad {
    padding: 5px;
    width: 100%;
}

.soc-wrapper {
    margin: auto;
    width: 135px;
}
