@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

#root {
    width: 100%;
    display: flex;
}

html {
    scroll-behavior: smooth;
}

:target::before {
    content: '';
    display: block;
    height: 60px;
    margin: -5px 0 0;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(70, 25, 51);
}

::-webkit-scrollbar-thumb {
    background: rgb(70, 25, 51);
}

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

%wrapper {
    display: flex;
}

$maxWidth: 1280;
@mixin adaptive-font($pcSize, $mobSize) {
    $addSize: $pcSize - $mobSize;
    $addMobSize: $addSize + $addSize * 0.7;
    @media (max-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
    }
    @media (min-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
    }
}

h1 {
    @include adaptive-font(58, 24);
}

h2 {
    @include adaptive-font(25, 19);
    margin-bottom: 2%;
}

h3 {
    @include adaptive-font(24, 19);
}

h4 {
    @include adaptive-font(22, 19);
}

h5 {
    @include adaptive-font(21, 18);
}

p {
    @include adaptive-font(20, 18);
}

ul {
    @include adaptive-font(23, 18);
}

li {
    @include adaptive-font(21, 19);
    list-style-position: inside;
}

a,
span {
    @include adaptive-font(22, 19);
}

select {
    @include adaptive-font(16, 12);
}

option {
    @include adaptive-font(16, 12);
}

@media (max-width: 1025px) {
    #root {
        display: block;
    }
}
