.professional-data {
    width: 68%;
    padding: 5%;
    background-image: url('../../../public/bg-main.jpeg');
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.5);

    h1,
    h2,
    h3,
    h4,
    h5,
    a,
    p,
    li {
        color: rgb(70, 25, 51);
    }
}

@media (max-width: 1025px) {
    .professional-data {
        width: 100%;
    }
}
