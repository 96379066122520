$maxWidth: 1280;
@mixin adaptive-font($pcSize, $mobSize) {
    $addSize: $pcSize - $mobSize;
    $addMobSize: $addSize + $addSize * 0.7;
    @media (max-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
    }
    @media (min-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
    }
}

nav {
    align-items: center;
    background-color: rgb(40, 37, 37);
    border-bottom: thick double rgb(134, 46, 97);
    border-radius: 0 0 20px 20px;
    color: whitesmoke;
    display: flex;
    justify-content: space-evenly;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

.main-menu ul li:hover, .start-main-menu ul li:hover {
    transform: scale(1.05);
    
    box-shadow: 3px 3px 6px #777;
    border-radius: 3px;
}

.logo-name {
    padding: 0.3%;
    cursor: pointer;
}

.letter-ico {
    filter: invert(0.8);
    height: 30px;
}

.pdf-ico {
    height: 35px;
}

.name {
    text-align: center;
    font-size: 150%;
    color: white;
    text-shadow: 3px 3px 3px rgba(247, 93, 93, 0.5);
    white-space: nowrap;
}

.specialization {
    text-align: center;
}

.menu-list li {
    white-space: nowrap;
    font-size: 80%;
    padding: 1%;
}

.start-menu-list li {
    white-space: nowrap;
    font-size: 80%;
    padding: 3%;
}

.menu-list li a, .start-menu-list li a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-menu-list {
    a {
        @include adaptive-font(21, 17);
    }
}

.main-menu, .start-main-menu {
    display: flex;
    width: 80%;
    margin: auto;
}

.logo {
    height: 50px;
    margin: 0;
    border: none;
}

.menu, .start-menu {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.menu-item {
    margin-left: 20px;
    color: rgb(233, 225, 230);
    text-decoration: none;
    font-size: 90%;
}

.burger {
    margin: 5px;
}

.burger-checkbox {
    visibility: hidden;
}

.menu-list, .start-menu-list {
    list-style-type: none;
    display: flex;
    align-items: center;
}

.soc-btns {
    display: none;
}


@media (max-width: 1050px) {
    .main-menu, .start-main-menu {
        width: 85%;
    }

    .menu-item {
        margin-left: 0;
    }
    
}

@media (max-width: 1025px) {
    .main-menu {
        display: block;
        width: 73%;
    }
    .menu {
        margin-left: initial;
    }
    .start-menu {       
        justify-content: center;
    }

    .menu-list li {
        padding: 2%;
    }

    .logo-name {
        margin-top: 1.5%;
    }

    .name {
        font-size: 183%;
    }
}

@media (max-width: 800px) {
    .main-menu {
        width: 100%;
    }
}

@media (max-width: 750px) {
    .menu-list li {
        padding: 1%;
    }
}

@media (max-width: 700px) {
    .soc-btns {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
    }

    .burger-checkbox {
        position: absolute;
    }
    .burger {
        position: relative;
        z-index: 1;
        cursor: pointer;
        display: block;
        position: relative;
        border: none;
        background: transparent;
        width: 40px;
        height: 26px;
    }
    .burger::before,
    .burger::after {
        content: '';
        left: 0;
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        border-radius: 10px;
        background: rgb(134, 46, 97);
    }
    .burger::before {
        top: 0;
        box-shadow: 0 11px 0 rgb(134, 46, 97);
        transition:
            box-shadow 0.3s 0.15s,
            top 0.3s 0.15s,
            transform 0.3s;
    }
    .burger::after {
        bottom: 0;
        transition:
            bottom 0.3s 0.15s,
            transform 0.3s;
    }
    .burger-checkbox:checked + .burger::before {
        top: 11px;
        transform: rotate(45deg);
        box-shadow: 0 6px 0 rgba(0, 0, 0, 0);
        transition:
            box-shadow 0.15s,
            top 0.3s,
            transform 0.3s 0.15s;
    }
    .burger-checkbox:checked + .burger::after {
        bottom: 11px;
        transform: rotate(-45deg);
        transition:
            bottom 0.3s,
            transform 0.3s 0.15s;
    }
    .menu-list, .start-menu-list {
        top: 0;
        right: 0;
        position: absolute;
        gap: 12px;
        padding: 70px 0;
        margin: 0;
        background: rgb(40, 37, 37);
        list-style-type: none;
        transform: translateX(+200%);
        transition: 0.3s;
        width: 100%;
        border-left: thick double rgb(134, 46, 97);
        border-bottom: thick double rgb(134, 46, 97);
        border-radius: 15px 0 15px 15px;
        display: block;
    }
    .menu-item {
        text-align: center;
        display: block;
        padding: 8% 0 10%;
        color: white;
        font-size: 130%;
        text-decoration: none;
        margin-left: 0;
    }
    .menu-item:hover {
        background: rgb(40, 37, 37);
    }
    .burger-checkbox:checked ~ .menu-list {
        transform: translateX(0);
    }

    .burger-checkbox:checked ~ .start-menu-list {
        transform: translateX(0);
    }    

    .pdf-ico {
        height: 75px;
    }

    .menu label {
        margin-left: auto;
        margin-bottom: 3%;
    }

    .main-menu, .start-main-menu {
        display: flex;
    }

    .menu, .start-menu {
        margin-left: auto;
    }

    .logo-name {
        margin-left: 3%;
    }

    .start-menu-list a {
        @include adaptive-font(22, 21);  
    }

    ul li:hover {
        transform: none;
        
        box-shadow: none;
        border-radius: 0;
    }
}
