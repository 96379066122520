.education-wrapper {
    border-top: 2px solid rgb(70, 25, 51);
    margin-bottom: 5%;
}

.education-wrapper h2 {
    margin-top: 2%;
    padding-bottom: 2%;
    border-bottom: 2px solid rgb(70, 25, 51);
    text-align: center;
    /*text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5);*/
}

.kurse-wrapper {
    display: flex;
    justify-content: space-between;
}

.kurse-cert {
    display: flex;
    align-items: center;
}

.certificate {
    max-height: 100px;
    cursor: pointer;
}

.kurses-block {
    line-height: 1.6;
    margin-bottom: 5%;
}

.high-education-block {
    border-top: 2px solid rgb(70, 25, 51);
}

.high-edu {
    line-height: 1.6;
}

.kurse-item {
    margin-bottom: 2%;
}

.education-wrapper a:link {
    color: rgb(134, 46, 97);
}

.education-wrapper a:hover {
    color: rgb(134, 46, 97);
}
.education-wrapper a:active {
    color: rgb(134, 46, 97);
}

@media (max-width: 1025px) {
    .high-edu {
        h2,
        h3,
        h4,
        h5,
        a {
            text-align: center;
        }

        a {
            display: block;
        }

        h2,
        h3 {
            text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5);
        }
    }

    .kurse-item span {
        display: block;
        text-align: center;
    }
}

@media (max-width: 450px) {
    .education-wrapper {
        border-top: none;

        h2 {
            padding: 2%;
            -webkit-box-shadow: 3px 3px 6px rgb(70, 25, 51);
            box-shadow: 3px 3px 6px rgb(70, 25, 51);
            border-radius: 3px;
            border-bottom: none;
            margin-top: 7%;
            margin-bottom: 7%;
        }

        .high-education-block {
            border-top: none;
        }
    }

    .kurse-wrapper {
        display: block;
    }

    .kurse-item {
        text-align: center;
        h3,
        h4 {
            text-align: center;
        }

        h3 {
            margin-top: 5%;
        }
    }

    .kurse-cert {
        justify-content: center;
    }

    .high-education {
        h2 {
            margin-top: 7%;
        }
    }
}
