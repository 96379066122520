.full-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.8);
    overflow: hidden;
    z-index: 1000;

    justify-content: center;
    align-items: center;
    display: flex;
}

.full-img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    object-position: center;
    cursor: all-scroll;
}

.close-button {
    width: 50px; /* размер кнопки */
    height: 50px; /* размер кнопки */
    background-color: rgb(70, 25, 51); /* Фон кнопки */
    border-radius: 50%; /* Делаем круг */
    position: absolute; /* Позиционируем кнопку внутри контейнера */
    top: 20px; /* Расстояние от верха контейнера */
    right: 20px; /* Расстояние от правого края контейнера */
    border: 1px solid white; /* Белая обводка */
    cursor: pointer;
}

.close-button:before,
.close-button:after {
    content: ' '; /* Псевдо-элементы для создания линий крестика */
    position: absolute;
    top: 46%;
    left: 15%;
    width: 70%;
    height: 4px; /* Толщина линии крестика */
    background-color: white; /* Цвет линии крестика */
    border-radius: 3px;
}

.close-button:before {
    transform: rotate(45deg); /* Поворот первой линии для создания крестика */
}

.close-button:after {
    transform: rotate(-45deg); /* Поворот второй линии */
}
