.experience-wrapper {
    line-height: 1.5;
    border-top: 2px solid rgb(70, 25, 51);
    margin-bottom: 5%;
}

.experience-wrapper h2 {
    margin-top: 2%;
    padding-bottom: 2%;
    border-bottom: 2px solid rgb(70, 25, 51);
    text-align: center;
    /*text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5); */
}

.experience-item {
    margin-bottom: 5%;

    ul li {
        list-style: inside;
    }

    h5 {
        /*text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5);*/
        margin-bottom: 2%;
    }

    li::marker {
        color: rgb(70, 25, 51);
    }
}

@media (max-width: 1025px) {
    .experience-item {
        width: 90%;
        margin: auto;

        h3 {
            margin-top: 3%;
        }
    }

    .experience-wrapper {
        h2,
        h3,
        h4,
        h5 {
            text-align: center;
        }
    }

    .experience-wrapper li {
        list-style-position: inside;
    }

    h2,
    h3 {
        text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5);
    }
}

@media (max-width: 950px) {
    .experience-wrapper li {
        list-style-position: inside;
        text-align: center;
    }
}

@media (max-width: 450px) {
    .experience-item {
        margin-top: 7%;
        li {
            border-bottom: 2px solid rgb(70, 25, 51);
        }
    }

    .experience-wrapper {
        border-top: none;

        h2 {
            margin-top: 7%;
            padding: 2%;
            -webkit-box-shadow: 3px 3px 6px #777;
            border-radius: 3px;
            border-bottom: none;
        }

        h4,
        h5 {
            text-shadow: 4px 5px 13px rgba(0, 0, 0, 0.5);
        }
    }
}
