.personal-data {
    width: 32%;
    background-color: rgb(70, 25, 51);
    padding: 5%;

    h2,
    h3,
    h4,
    p,
    a,
    li {
        color: white;
    }

    h2,
    h3 {
        text-align: center;
    }
}

@media (max-width: 1280px) {
    .personal-data {
        padding: 3%;
    }
}

@media (max-width: 1025px) {
    .personal-data {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .personal-data {
    }
}

@media (max-width: 650px) {
    .personal-data {
    }
}
